export const notes = [
    'Parking and Toll charges extra.',
    'Total KM and Time calculation from office to office.',
    'Driver Allowance will be extra if the driver drives between 10.00pm to 6.00am.',
    'Season time 10% extra.',
    'The above cost including Cab Rent, Fuel & Driver Allowance.',
    'Parking & Toll interstate permit charges extra.',
    'lf any deviation in the dropping route it will be charged extra.',
    'Ac will not work in Hill areas (upwards) and stopped / parked vehicle.',
    'Addition charges of 150 will be collected per hour if the time exceeds the prescribed time'
];