import React, { useEffect } from "react";

// import carData from "../assets/data/carData";
import packageData from "../assets/data/packageData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
// import BookingForm from "../components/UI/BookingForm";
// import PaymentMethod from "../components/UI/PaymentMethod";
import FindCarForm from "../components/UI/FindCarForm";

const PackageDetails = () => {
  const { slug } = useParams();

  const singleCarItem = packageData.find((item) => item.packageName === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleCarItem]);

  return (
    <Helmet title={singleCarItem.packageName}>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <img src={singleCarItem.imgUrl} alt="" className="w-100 h-100" />
            </Col>

            <Col lg="6">
              <div className="car__info">
                <h2 className="section__title">{singleCarItem.packageName}</h2>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    ₹{singleCarItem.minPrice}.00/- onwards
                  </h6>

                  {/* <span className=" d-flex align-items-center gap-2">
                    <span style={{ color: "#f9a826" }}>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                    </span>
                    ({singleCarItem.rating} ratings)
                  </span> */}
                </div>

                <p className="section__description">
                  {singleCarItem.description}
                </p>

                {/* <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "4rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-roadster-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.model}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-settings-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.automatic}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-timer-flash-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.speed}
                  </span>
                </div> */}

                <div
                  className=" d-flex align-items-center mt-3 flex-wrap"
                  style={{ columnGap: "4rem" }}
                >
                  {singleCarItem.places.map((item, ind) => (
                    <span className=" d-flex align-items-center gap-1 section__description" key={ind}>
                      <i class="ri-map-pin-line" 
                      style={{ color: "#f9a826" }}>
                      </i>{" "}
                      {item}
                    </span>
                  ))}
                  {/* <span className=" d-flex align-items-center gap-1 section__description">
                    <i class="ri-map-pin-line" 
                    style={{ color: "#f9a826" }}>
                    </i>{" "}
                    {singleCarItem.gps}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-wheelchair-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.seatType}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-building-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.brand}
                  </span> */}
                </div>
                
              </div>
              <div className="d-flex flex-column mt-3 mb-3 section__description">
                {singleCarItem.prices.map((item, ind)=>(<div key={ind}><b className="text-capitalize">{item.car}:</b>  ₹{item.price}/-</div>))}
              </div>
                
            </Col>

            <Col lg="12" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="fw-bold ">Booking Information</h5>
                <FindCarForm />  
              </div>
            </Col>

            <Col lg="12">
              <div className="booking-info mt-5">
                <h5 className="fw-bold ">Notes</h5>
                <div className="section__description">
                  {singleCarItem.notes.map((item, ind)=>(
                    <p className="mb-0" key={ind}>{ `${ind+1}) ${item}`}</p>
                  ))}
                </div>
              </div>
            </Col>

            {/* <Col lg="5" className="mt-5">
              <div className="payment__info mt-5">
                <h5 className="mb-4 fw-bold ">Payment Information</h5>
                <PaymentMethod />
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default PackageDetails;
